.blurred-img {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
}

.blurred-background {
  position: absolute;
  inset: 0;
  background-size: cover;
  filter: blur(10px); /* Adjust blur as desired */
  transition: opacity 0.5s ease-in-out;
}

.blurred-img img {
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.blurred-img.loaded .blurred-background {
  opacity: 0;
}

.blurred-img.loaded img {
  opacity: 1;
}

.react-responsive-masonry {
  display: flex;
  margin-left: -1rem;
  width: auto;
}

.react-responsive-masonry .masonry {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
}

.react-responsive-masonry .masonry .masonry-brick {
  width: calc(33.33% - 2rem);
  margin: 1rem;
}
